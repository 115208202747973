@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

html,
body {
  /* minimal breakpoint 475px */
  width: auto !important;
}

a:hover {
  @apply underline;
}

@media only screen and (max-width: 474px) {
  html {
    /* workaround to comprise 475px layout on smaller screens
      without hacky initial-scale, transform, as everythings in REM */
    font-size: 14px;
  }
}

ul,
ol,
li {
  @apply list-inside;
}

.subpage p {
  @apply mb-2 text-base;
}

.subpage h1 {
  @apply mb-2 text-2xl font-bold;
}

.subpage h2 {
  @apply mb-2 text-xl font-bold;
}

.subpage h3 {
  @apply mb-2 text-lg font-bold;
}

.content-block ul {
  @apply list-disc;
}

.content-block ol {
  @apply list-decimal;
}

.content-block li {
  @apply my-2;
}

@media only screen and (max-width: 500px) {
  #chat-button {
    bottom: 100px !important;
  }
}

/* Override because Gorgias wants to be on top of cookie banner */
#cookiescript_injected_wrapper {
  position: relative;
  z-index: 2147483001;
}
